import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const GRANTS_LOCALES = {
	WOLTAIR_CZ: 'dotace',
	WOLTAIR_PL: 'dotacje',
	WOLTAIR_DE: 'foerderung',
	WOLTAIR_IT: null
}

export const GRANTS = GRANTS_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return GRANTS === param
}) satisfies ParamMatcher
